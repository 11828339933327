import Vue from 'vue'

// division select
Vue.component('division-select-component', () => import('./components/location/DivisionSelectComponent'))
// district select
Vue.component('district-select-component', () => import('./components/location/DistrictSelectComponent'))
// upazila select
Vue.component('upazila-select-component', () => import('./components/location/UpazilaSelectComponent'))
// particular create component
Vue.component('create-particular-component', () => import('./components/particular/CreateParticularComponent'))
// Transaction create component
Vue.component('create-transaction-component', () => import('./components/transaction/CreateTransactionComponent'))
// Product Create Component
Vue.component('create-product-component', () => import('./components/product/CreateProductComponent'))
// Product Search Component
Vue.component('product-search-company-group-subgroup', () => import('./components/product/search-form/CompanyGroupSubgroupComponent'))
// Product Update Component
Vue.component('update-product-component', () => import('./components/product/UpdateProductComponent'))
// Product Purchase Component
Vue.component('product-purchase-component', () => import('./components/purchase/PurchaseProductComponent'))
Vue.component('purchase-container-component', () => import('./components/purchase/PurchaseContainerComponent'))
// Purchase Return Component
Vue.component('product-purchase-return-container-component', () => import('./components/purchase/PurchaseReturnContainerComponent'))
// Checkout Component
Vue.component('pos-checkout-component', () => import('./components/pos/components/POSCheckoutContainerComponent'))
// Sale Return Component
Vue.component('sale-return-component', () => import('./components/pos/components/SaleReturnContainerComponent'))
// Product Transfer Component
Vue.component('product-transfer-creat-component', () => import('./components/product-transfer/ProductTransferCreatComponent'))
// Product Transfer Component
Vue.component('product-transfer-update-component', () => import('./components/product-transfer/ProductTransferUpdateComponent'))
// Due Manage Component
Vue.component('manage-due-create', () => import('./components/due-manage/ManageDueCreateComponent'))
// customer due update component
Vue.component('customer-due-update', () => import('./components/due-manage/CustomerDueUpdateComponent'))
// supplier due update component
Vue.component('supplier-due-update', () => import('./components/due-manage/SupplierDueUpdateComponent'))
// Balance Transfer Component
Vue.component('bank-transaction-component', () => import('./components/balance-transfer/TransactionComponent'))
Vue.component('transaction-edit', () => import('./components/balance-transfer/TransactionEdit'))
// Advanced Salary Create Component
Vue.component('advanced-salary-create', () => import('./components/advanced-salary/AdvanceSalaryCreateComponent'))
//Salary Create Component
Vue.component('create-salary', () => import('./components/salary/CreateSalaryComponent'))
// Selected User Salary Component
Vue.component('selected-user-salary', () => import('./components/salary/SelectedUserSalaryComponent'))
Vue.component('customer-area-select', () => import('./components/customer/CustomerAreaSelect'))
Vue.component('expense-entry-component', () => import('./components/expense/ExpenseEntryComponent'))
Vue.component('expense-edit-component', () => import('./components/expense/ExpenseEditComponent'))
// sale update component
Vue.component('pos-update-component', () => import('./components/pos/update-component/SaleUpdateComponent'))
Vue.component('invoice-select-component', () => import('./components/invoice/InvoiceSelectComponent'))
// sale return create component
Vue.component('sale-return-create-component', () => import('./components/sale-return/SaleReturnCreateComponent'))
// Party Area
Vue.component('party-area-component', () => import('./components/party/PartyAreaComponent'))
Vue.component('party-by-area-component', () => import('./components/party/PartyByAreaComponent'))
Vue.component('purchase-update-component', () => import('./components/purchase/update-component/PurchaseUpdateComponent'))
Vue.component('product-index', () => import('./components/product/IndexProductComponent'))
// Production Create Component
Vue.component('production-create-component', () => import('./components/production/ProductionCreateComponent'))
Vue.component('customer-by-area', () => import('./components/customer/CustomerByAreaComponent'))
Vue.component('expense-list-sectior', () => import('./components/customer/ExpenseListComponent'))
//sale return component
Vue.component('purchase-return-component', () => import('./components/pruchase-return/PurchaseReturnComponent.vue'))




